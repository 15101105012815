import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ocorrenciaStore } from '@/utils/store-accessor';
let TarefaChangeStatusComponent = class TarefaChangeStatusComponent extends Vue {
    constructor() {
        super(...arguments);
        this.currentItem = null;
        this.currentItemObservacao = '';
    }
    onCancel() {
        this.$emit('on-close');
    }
    async realizarTarefa(tarefa_status_id) {
        const obj = {
            id: this.propOcorrenciaTarefaId,
            status_id: this.propStatusId,
            observacao: this.currentItemObservacao,
        };
        await ocorrenciaStore.updateOcorrenciaTarefa(obj);
        this.$emit('on-confirm');
        this.$emit('on-close');
    }
};
__decorate([
    Prop({ type: Number, default: null })
], TarefaChangeStatusComponent.prototype, "propOcorrenciaTarefaId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], TarefaChangeStatusComponent.prototype, "propStatusId", void 0);
TarefaChangeStatusComponent = __decorate([
    Component
], TarefaChangeStatusComponent);
export default TarefaChangeStatusComponent;
